import { render, staticRenderFns } from "./product.vue?vue&type=template&id=c5136186&scoped=true&"
import script from "./product.ts?vue&type=script&lang=ts&"
export * from "./product.ts?vue&type=script&lang=ts&"
import style0 from "./product.scoped.scss?vue&type=style&index=0&id=c5136186&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5136186",
  null
  
)

export default component.exports