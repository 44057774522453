import Decimal from 'decimal.js';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface ReportSummaryOption<T> {
  /**
   * 要展示值的属性名称
   */
  prop: keyof T;
  /**
   * 值的简洁描述
   */
  name: string;
  /**
   * 关于值的具体描述
   */
  tip?: string;
  /**
   * 值的单位
   */
  unit?: string;
  /**
   * 值前缀
   */
  prefix?: string;
  /**
   * 保留几位小数
   */
  toFixd?: number;

  /**
   * 页面路由
   */
  path?: string;
  /**
   * 查询方案名称
   */
  queryPlanName?: string;
}

@Component({
  components: {}
})
export default class ReportSummary extends Vue {
  @Prop({ type: String, required: true })
  public title!: string;

  @Prop({ type: Array, required: true })
  public reportOptions!: Array<ReportSummaryOption<object>>;

  @Prop({ required: true, type: Object })
  public data!: { [p: string]: any };

  public format(item: ReportSummaryOption<any>): string {
    if (item.toFixd) {
      return new Decimal(this.data[item.prop as string] || 0).toFixed(item.toFixd);
    }
    return this.data[item.prop as string] || 0;
  }
  /**
   * 页面跳转
   */
  public pageJump(item: ReportSummaryOption<object>): void {
    if (item.path && item.path.length > 0) {
      console.log('跳转--->', item);
      this.$router.push({ path: `${item.path}`, query: { queryPlanName: item.queryPlanName } });
    }
  }
}
