import { Component, Vue } from 'vue-property-decorator';
import LogisticTodo from './logistic-todo/logistic-todo.vue';
import ProductionTodo from './production-todo/production-todo.vue';
import Production from './production/production.vue';
import Product from './product/product.vue';
import ExceptionReport from './exception-report/exception-report.vue';
import { PermissionModule } from '@/store/modules/permission';
@Component({
  components: { LogisticTodo, ProductionTodo, Production, Product, ExceptionReport }
})
export default class extends Vue {
  public show(permissionCode: string): boolean {
    const operations: Array<string> = PermissionModule.permissionOperations;
    return operations.includes(permissionCode);
  }
}
