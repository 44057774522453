import { DashboardService } from '@/api';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsShortcutsOptions } from '@/components/os-time-picker-linkage/os-time-picker-linkage';
import { DeliveryProductSummary } from '@/resource/model';
import { messageError } from '@/utils';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Product extends Vue {
  public reportQuery = {
    rangeTime: [],
    code: ''
  };

  public shortcutsOptions: Array<OsShortcutsOptions> = [
    {
      text: 'dashboard.today',
      value: 'today',
      rangeTime(): [Date, Date] {
        const start = new Date();
        start.setDate(1);
        return [
          moment()
            .startOf('day')
            .toDate(),
          moment()
            .endOf('day')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.thisWeek',
      value: 'week',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('week')
            .toDate(),
          moment()
            .endOf('week')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.thisMonth',
      value: 'month',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('month')
            .toDate(),
          moment()
            .endOf('month')
            .toDate()
        ];
      }
    }
  ];
  /**
   * 表格配置
   */
  public tableOption: OsTableOption<DeliveryProductSummary> = {
    loading: false,
    data: [],
    fit: true,
    size: 'mini',
    rowKey: (): string => {
      return 'uuid';
    },
    treeProps: { children: 'detailList' },
    closeAdaptiveHeight: true
  };
  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<DeliveryProductSummary>> = [
    {
      prop: 'name',
      label: 'productDeliveryReport.deliveryDateName',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'totalNumber',
      label: 'productDeliveryReport.number',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'totalArea',
      label: 'productDeliveryReport.area',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  public showErrorTip = false;

  public loadData(): void {
    if (this.reportQuery.code.length > 0 && this.reportQuery.code.length < 14) {
      this.showErrorTip = true;
      return;
    } else {
      this.showErrorTip = false;
    }
    const [startTime, endTime] = this.reportQuery.rangeTime;
    const query = { code: this.reportQuery.code, startTime, endTime };

    DashboardService.getProductReport(query)
      .then(res => {
        this.tableOption.data = res.map(x => {
          x.uuid = uniqueId('p');
          x.detailList.map(y => {
            y.uuid = uniqueId('p');
            return y;
          });
          return x;
        });
      })
      .catch(error => {
        messageError(error);
      });
  }
}
