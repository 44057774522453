import { Component, Vue } from 'vue-property-decorator';
import ReportSummary from '../report-summary/report-summary.vue';
import { OsShortcutsOptions } from '@/components/os-time-picker-linkage/os-time-picker-linkage';
import { ProductionReport } from '@/resource/model';
import moment from 'moment';
import { ReportSummaryOption } from '../report-summary/report-summary';
import { DashboardService } from '@/api';
import { messageError } from '@/utils';

@Component({
  components: { ReportSummary }
})
export default class Production extends Vue {
  public reportQuery = {
    rangeTime: [],
    customerId: undefined
  };

  public shortcutsOptions: Array<OsShortcutsOptions> = [
    {
      text: 'dashboard.today',
      value: 'today',
      rangeTime(): [Date, Date] {
        const start = new Date();
        start.setDate(1);
        return [
          moment()
            .startOf('day')
            .toDate(),
          moment()
            .endOf('day')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.thisWeek',
      value: 'week',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('week')
            .toDate(),
          moment()
            .endOf('week')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.thisMonth',
      value: 'month',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('month')
            .toDate(),
          moment()
            .endOf('month')
            .toDate()
        ];
      }
    }
  ];

  public reportOptions: Array<ReportSummaryOption<ProductionReport>> = [
    {
      name: 'productionReport.productionArea',
      prop: 'producedArea',
      unit: '㎡',
      toFixd: 2
    },
    {
      name: 'productionReport.inProductionArea',
      prop: 'inProductionArea',
      unit: '㎡',
      toFixd: 2
    },
    {
      name: 'productionReport.waitPrintArea',
      prop: 'toBePrintTotalArea',
      unit: '㎡',
      toFixd: 2
    },
    {
      name: 'productionReport.waitBackendArea',
      prop: 'toBeBackendTotalArea',
      unit: '㎡',
      toFixd: 2
    },
    {
      name: 'productionReport.consumedArea',
      prop: 'attritionTotalArea',
      unit: '㎡',
      toFixd: 2
    },
    {
      name: 'productionReport.proofingArea',
      prop: 'proofingTotalArea',
      unit: '㎡',
      toFixd: 2
    }
  ];
  public data: ProductionReport | {} = {};

  public loadData(): void {
    const [startTime, endTime] = this.reportQuery.rangeTime;
    const query = { customerId: this.reportQuery.customerId, startTime, endTime };
    DashboardService.getProductionReport(query)
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
