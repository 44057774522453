import { DashboardService } from '@/api';
import { ProductionTodoReport } from '@/resource/model';
import { messageError } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { ReportSummaryOption } from '../report-summary/report-summary';
import ReportSummary from '../report-summary/report-summary.vue';

@Component({
  components: { ReportSummary }
})
export default class ProductionTodo extends Vue {
  public reportOptions: Array<ReportSummaryOption<ProductionTodoReport>> = [
    {
      name: 'productionTodo.toBeReceiveProduced',
      prop: 'toBeReceiveProduced',
      queryPlanName: '待接单',
      path: '/production-order'
    },
    {
      name: 'productionTodo.toBeScheduling',
      prop: 'toBeScheduling',
      queryPlanName: '待排产',
      path: '/production-order'
    },
    {
      name: 'productionTodo.toBeConfirmChange',
      prop: 'toBeConfirmChange',
      queryPlanName: '待审核',
      path: '/production-order-change'
    }
  ];

  public data: ProductionTodoReport | {} = {};

  public created(): void {
    DashboardService.getProductionTodoReport()
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
