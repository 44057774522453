import { DashboardService } from '@/api';
import { LogisticTodoReport } from '@/resource/model';
import { messageError } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { ReportSummaryOption } from '../report-summary/report-summary';
import ReportSummary from '../report-summary/report-summary.vue';
@Component({
  components: { ReportSummary }
})
export default class LogisticTodo extends Vue {
  public reportOptions: Array<ReportSummaryOption<LogisticTodoReport>> = [
    {
      name: 'logisticTodo.toBeReceiveShip',
      prop: 'toBeReceiveShip',
      queryPlanName: '待发货',
      path: '/delivery-notice'
    },
    {
      name: 'logisticTodo.toBeShip',
      prop: 'toBeShip',
      queryPlanName: '待发货',
      path: '/logistics'
    },
    {
      name: 'logisticTodo.errorLogisticsOrder',
      prop: 'errorLogisticsOrder',
      queryPlanName: '异常',
      path: '/logistics'
    },
    {
      name: 'logisticTodo.overdueNotReceived',
      prop: 'overdueNotReceived',
      queryPlanName: '逾期未签收',
      path: '/logistics'
    },
    {
      name: 'logisticTodo.overdueLogisticsOrder',
      prop: 'overdueLogisticsOrder',
      queryPlanName: '即将逾期',
      path: '/logistics'
    }
  ];

  public data: LogisticTodoReport | {} = {};

  public created(): void {
    DashboardService.getlogisticTodoReport()
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
