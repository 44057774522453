import { DashboardService } from '@/api';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { DeliveryModeEnum, LogisticsOrderStatusEnum, LogisticsStatusEnum } from '@/resource/enum';
import { LogisticsList, LogisticsListQuery } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Watch, Prop } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class SlippageWarning extends mixins(PagingMixin, CustomColumnMixin) {
  @Prop({
    required: true,
    type: Array
  })
  public customersOption: Array<Object> | undefined;
  public tableOption: OsTableOption<LogisticsList> = {
    loading: false,
    data: [],
    fit: true,
    height: '400px',
    closeAdaptiveHeight: true
  };

  public defaultColumnOptions: Array<
    OsTableColumn<LogisticsList & { deliveryShopName: string; logisticsTrack: string }>
  > = [
    {
      prop: 'code',
      label: 'logistics.code',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'logistics.relevanceCustomer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'logistics.relevanceProject',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'deliveryShopName',
      label: 'logistics.shopName',
      minWidth: '150px',
      showOverflowTooltip: true
    },

    {
      prop: 'status',
      label: 'logistics.status',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'logisticsStatus',
      label: 'logistics.logisticsStatus',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    // {
    //   prop: 'logisticsTrack',
    //   label: 'logistics.logisticsTrack',
    //   showOverflowTooltip: true,
    //   minWidth: '150px'
    // },
    {
      prop: 'newLogisticsTrack',
      label: 'logistics.newLogisticsTrack',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'requiredArriveTime',
      label: 'logistics.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'expressName',
      label: 'orderLogisticsOrder.shippingCompany',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'logisticsNo',
      label: 'logistics.logisticsNo',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  public queryItemsOption: Array<
    OsQueryItemOption<
      LogisticsListQuery & {
        deliveryTime: Array<string>;
        receivingTime: Array<string>;
        requiredArriveTime: Array<string>;
      }
    >
  > = [
    {
      type: 'Input',
      field: 'code',
      label: 'logistics.code',
      option: {
        placeholder: 'logistics.inputCode'
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'logistics.relevanceCustomer',
      option: {
        placeholder: 'logistics.selectCustomer',
        filterable: true
      },
      optionData: []
    }
  ];

  private queryForm: Partial<LogisticsListQuery> = {
    customerId: undefined,
    code: ''
  };

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 's-warning');
    this.loadData();
  }

  public reloadData(): void {
    this.clearSelection();
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.paging.currentPage = 1;
    this.reloadData();
  }
  public handleSelectionChange(selectedData: Array<LogisticsList>): void {
    console.log('选中数据', selectedData);
  }
  public linkToDetails(logistics: LogisticsList): void {
    this.$router.push({
      path: 'logistics-details',
      query: {
        id: logistics.id.toString()
      }
    });
  }

  public getStatusClass(logistics: LogisticsList): string {
    switch (logistics.status) {
      case LogisticsOrderStatusEnum.new:
        return 'info-dot';
      case LogisticsOrderStatusEnum.inTransit:
        return 'primary-dot';
      case LogisticsOrderStatusEnum.closed:
        return 'success-dot';
      case LogisticsOrderStatusEnum.received:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  public getStatusName(logistics: LogisticsList): string {
    if (!LogisticsOrderStatusEnum[logistics.status]) {
      return translation('common.unKnownStatus');
    }
    return `logisticsOrderStatus.${LogisticsOrderStatusEnum[logistics.status]}`;
  }

  public getlogisticsStatusClass(logistics: LogisticsList): string {
    switch (logistics.logisticsStatus) {
      case LogisticsStatusEnum.new:
        return 'info-dot';
      case LogisticsStatusEnum.signBack:
        return 'primary-dot';
      case LogisticsStatusEnum.customsClearance:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }
  public getlogisticsStatusName(logistics: LogisticsList): string {
    if (!LogisticsStatusEnum[logistics.logisticsStatus]) {
      return 'common.unKnownStatus';
    }
    return `logisticsStatus.${LogisticsStatusEnum[logistics.logisticsStatus]}`;
  }

  public getDeliveryModeName(mode: DeliveryModeEnum): string {
    return `deliveryMode.${DeliveryModeEnum[mode]}`;
  }

  private clearSelection(): void {
    (this.$refs.logisticsEexceptTable as OsTable).clearSelection();
  }

  private loadData(): void {
    this.tableOption.loading = true;
    DashboardService.getWarningList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('customersOption')
  private setCustomersOption(): void {
    const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
    customerQuery!.optionData = this.customersOption;
  }
}
