import { Component, Vue } from 'vue-property-decorator';
import slippageWarning from './slippage-warning/slippage-warning.vue';
import slippageUnsigned from './slippage-unsigned/slippage-unsigned.vue';
import logisticsExcept from './logistics-except/logistics-except.vue';
import { logisticsService } from '@/api';
import { messageError } from '@/utils';

export type ActiveTab = 'logisticsExcept' | 'slippageUnsigned' | 'slippageWarning';

@Component({
  components: {
    slippageWarning,
    slippageUnsigned,
    logisticsExcept
  }
})
export default class ExceptionReport extends Vue {
  public activeTab: ActiveTab = 'logisticsExcept';

  public customersOption: Array<{ label: string; value: number }> = [];

  public tabClick(event: any): void {
    event.$children[0].loadData();
  }

  public created(): void {
    this.getCustomers();
  }

  private getCustomers(): void {
    logisticsService
      .getProjectCustomer()
      .then(res => {
        this.customersOption = res.map(x => {
          return { label: x.customerName, value: x.customerId };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }
}
